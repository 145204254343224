var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.$v.$error && _vm.$v.$invalid)?_c('div',{staticClass:"error-box"},[_c('div',{staticClass:"flex flex-col w-10/12 justify-center"},[(!_vm.$v.emailInput.email)?_c('span',{staticClass:"flex"},[_vm._v("Meiliaadress on ebakorrektne")]):_vm._e(),(
          _vm.phoneNumberInput.length > 0 &&
            (!_vm.$v.phoneNumberInput.minLength || !_vm.$v.phoneNumberInput.maxLength)
        )?_c('span',{staticClass:"flex"},[_vm._v("Telefoninumber on ebakorrektne (7-8 numbrit)")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"flex mb-4 w-full flex-col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],staticClass:"add-project-input w-full mb-4",attrs:{"placeholder":"Nimi"},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailInput),expression:"emailInput"}],staticClass:"add-project-input w-full",attrs:{"placeholder":"Meiliaadress"},domProps:{"value":(_vm.emailInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailInput=$event.target.value}}})]),_c('div',{staticClass:"flex mb-4 w-full flex-col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.role),expression:"contact.role"}],staticClass:"add-project-input w-full mb-4",attrs:{"placeholder":"Nimetus"},domProps:{"value":(_vm.contact.role)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "role", $event.target.value)}}}),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"flex pr-2 w-3/12"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.countryCode),expression:"countryCode"}],staticClass:"w-full",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.countryCode=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_vm._m(0)])]),_c('div',{staticClass:"flex w-9/12 pl-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumberInput),expression:"phoneNumberInput"}],staticClass:" add-project-input w-full",class:_vm.phoneNumberInput.length > 0 &&
            _vm.$v.$error &&
            _vm.$v.phoneNumberInput.$invalid
              ? 'shadow-dangeroutline'
              : '',attrs:{"placeholder":"Telefoninumber","type":"tel","pattern":"[0-9]*"},domProps:{"value":(_vm.phoneNumberInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumberInput=$event.target.value}}})])])]),_c('div',{staticClass:"flex flex-row justify-start mb-8"},[_c('button',{staticClass:"new-button-green mr-4",on:{"click":_vm.save}},[_c('span',{staticClass:"icon typcn typcn-tick"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta kontakt")])]),_c('button',{staticClass:"new-button-danger",on:{"click":function($event){return _vm.close()}}},[_c('span',{staticClass:"icon typcn typcn-times"}),_c('span',{staticClass:"label"},[_vm._v("Tühista lisamine")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('option',{attrs:{"value":"+372"}},[_c('img',{attrs:{"src":""}}),_vm._v("+372")])}]

export { render, staticRenderFns }