<template>
  <div class="flex flex-col w-full">
    <div v-if="$v.$error && $v.$invalid" class="error-box">
      <div class="flex flex-col w-10/12 justify-center">
        <span class="flex" v-if="!$v.emailInput.email"
          >Meiliaadress on ebakorrektne</span
        >
        <span
          class="flex"
          v-if="
            phoneNumberInput.length > 0 &&
              (!$v.phoneNumberInput.minLength || !$v.phoneNumberInput.maxLength)
          "
          >Telefoninumber on ebakorrektne (7-8 numbrit)</span
        >
      </div>
    </div>
    <div class="flex mb-4 w-full flex-col">
      <input
        class="add-project-input w-full mb-4"
        placeholder="Nimi"
        v-model="contact.name"
      />
      <input
        class="add-project-input w-full"
        placeholder="Meiliaadress"
        v-model="emailInput"
      />
    </div>
    <div class="flex mb-4 w-full flex-col">
      <input
        class="add-project-input w-full mb-4"
        placeholder="Nimetus"
        v-model="contact.role"
      />
      <div class="flex w-full">
        <div class="flex pr-2 w-3/12">
          <select v-model="countryCode" class="w-full">
            <option value="+372"><img src="" />+372</option>
          </select>
        </div>
        <div class="flex w-9/12 pl-2">
          <input
            class=" add-project-input w-full"
            placeholder="Telefoninumber"
            v-model="phoneNumberInput"
            type="tel"
            pattern="[0-9]*"
            :class="
              phoneNumberInput.length > 0 &&
              $v.$error &&
              $v.phoneNumberInput.$invalid
                ? 'shadow-dangeroutline'
                : ''
            "
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-start mb-8">
      <button @click="save" class="new-button-green mr-4">
        <span class="icon typcn typcn-tick"></span>
        <span class="label">Salvesta kontakt</span>
      </button>
      <button @click="close()" class="new-button-danger">
        <span class="icon typcn typcn-times"></span>
        <span class="label">Tühista lisamine</span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";

export default {
  name: "CompanyClientContact",
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phoneNumberInput: "",
      countryCode: "+372",
      emailInput: "",
      contact: {
        name: "",
        email: "",
        telephone_number: "",
        role: ""
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        !(
          this.contact.name === "" &&
          this.contact.email === "" &&
          this.contact.role === ""
        )
      ) {
        if (this.phoneNumberInput.length > 0) {
          this.contact.telephone_number =
            this.countryCode + this.phoneNumberInput;
        }
        this.$emit("save", this.contact);
        this.close();
      }
    }
  },
  watch: {
    emailInput() {
      this.contact.email = this.emailLowercased;
    },
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.phoneNumberInput = this.phoneNumberInput.replace(test, "");
      }
    }
  },
  computed: {
    emailLowercased() {
      return this.emailInput.toLowerCase();
    }
  },
  validations() {
    if (this.phoneNumberInput !== null && this.phoneNumberInput.length > 0) {
      return {
        phoneNumberInput: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(8)
        },
        emailInput: {
          email
        }
      };
    } else {
      return {
        emailInput: {
          email
        }
      };
    }
  }
};
</script>
