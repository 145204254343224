<template>
  <div>
    <div class="flex flex-col mb-8">
      <div
        class="flex items-center mb-6 w-4/12 mobile:w-full"
        :disabled="Object.keys(existingClient).length > 0"
        :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
      >
        <base-checkbox
          :base-val="client.is_company"
          @valueChanged="
            e => {
              client.is_company = e;
            }
          "
        ></base-checkbox>
        <h4 class="ml-2">Klient on ettevõte</h4>
      </div>
      <form-label
        :title="client.is_company ? 'Ettevõtte nimi' : 'Nimi'"
        :required="true"
        :valid="!$v.client.name.$invalid"
        :error="$v.client.name.$error"
      ></form-label>
      <input
        class="mb-4 add-project-input"
        :class="[
          $v.$error && $v.client.name.$invalid ? 'shadow-dangeroutline' : '',
          Object.keys(existingClient).length > 0 ? 'disabled' : ''
        ]"
        :disabled="Object.keys(existingClient).length > 0"
        v-model="client.name"
      />
      <form-label
        title="Ettevõtte registrikood"
        v-if="client.is_company"
      ></form-label>
      <input
        v-if="client.is_company"
        class="mb-4 add-project-input"
        placeholder="Ettevõtte registrikood"
        :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
        v-model="client.register_code"
        :disabled="Object.keys(existingClient).length > 0"
      />
      <form-label
        title="Ettevõtte KMRK kood"
        v-if="client.is_company"
      ></form-label>
      <input
        v-if="client.is_company"
        class="mb-4 add-project-input"
        placeholder="Ettevõtte KMKR kood"
        :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
        v-model="client.tax_code"
        :disabled="Object.keys(existingClient).length > 0"
      />
      <form-label
        :title="client.is_company ? 'Ettevõtte meiliaadress' : 'Meiliaadress'"
      ></form-label>
      <input
        class="mb-4 add-project-input"
        v-model="client.email"
        :disabled="Object.keys(existingClient).length > 0"
        :class="[
          $v.$error && $v.client.email.$invalid ? 'shadow-dangeroutline' : '',
          Object.keys(existingClient).length > 0 ? 'disabled' : ''
        ]"
      />
      <form-label
        :title="
          client.is_company ? 'Ettevõtte telefoninumber' : 'Telefoninumber'
        "
      ></form-label>
      <div class="w-full flex mb-6">
        <select
          v-model="countryCode"
          class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
          :disabled="Object.keys(existingClient).length > 0"
          :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
        >
          <option value="+372"><img src="" />+372</option>
        </select>
        <input
          class="mobile:w-9/12 medium:w-9/12 w-10/12 add-project-input"
          v-model="phoneNumberInput"
          :disabled="Object.keys(existingClient).length > 0"
          :class="Object.keys(existingClient).length > 0 ? 'disabled' : ''"
          @input="assignPhoneNumber"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
      <form-label
        :title="client.is_company ? 'Ettevõtte aadress' : 'Kliendi aadress'"
      ></form-label>
      <div
        class="flex flex-row items-center mb-4 w-full"
        v-if="!client.address && project && project.location"
      >
        <div class="ml-2 items-center flex">
          <span class="flex mr-2">Sama, mis projektil</span>
          <input
            type="checkbox"
            class="flex ml-2 shadow-none focus:shadow-none"
            @click="client.address = project.location"
          />
        </div>
      </div>
      <location-inaddress
        v-if="!client.address"
        :text="
          client.is_company
            ? 'Otsi ettevõtte aadressi'
            : 'Otsi kliendi aadressi'
        "
        @locationChanged="handleClientLocationChange"
        class="mb-6"
      >
      </location-inaddress>
      <div v-if="client.address" class="mb-4 flex items-center">
        <span>
          {{
            Object.keys(existingClient).length > 0
              ? client.address.long_address
              : client.address.ipikkaadress
          }}
        </span>
        <button
          class="new-button-danger ml-2"
          @click="client.address = null"
          v-if="!Object.keys(existingClient).length > 0"
        >
          <span class="icon">
            <img src="/bc21/trash.svg" class="filter-to-white" />
          </span>
          <span class="label pl-2 pr-3">Tühjenda</span>
        </button>
      </div>
      <form-label title="Kontaktisikud"></form-label>
      <company-client-contact
        v-if="contactDetails"
        @close="contactDetails = false"
        @save="saveClientContact"
      >
      </company-client-contact>
      <div v-if="clientContacts.length > 0" class="flex flex-col w-full">
        <div class="flex flex-col">
          <div
            v-for="(contact, index) in clientContacts"
            :key="contact.name + contact.email"
            class="flex mb-2"
          >
            <div class="flex w-5/12 flex-col">
              <span class="flex"> Nimi: {{ contact.name }}</span>
              <span class="flex"> Nimetus: {{ contact.role }}</span>
            </div>
            <div class="flex w-5/12 flex-col">
              <span v-if="contact.telephone_number.length > 0" class="flex">
                {{ countryCode }}
                {{ contact.telephone_number.substring(4) }}</span
              >
              <span class="flex"> Meiliaadress: {{ contact.email }}</span>
            </div>
            <div class="flex w-2/12 flex-col items-center">
              <button class="alt-button-danger" @click="removeContact(index)">
                <span class="icon typcn typcn-times"></span>
                <span class="label">Eemalda</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-if="!contactDetails">
        <button class="alt-button-green" @click="contactDetails = true">
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Lisa kontaktisik</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyClientContact from "@/components/project/CompanyClientContact";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { email, required } from "vuelidate/lib/validators";

export default {
  components: {
    CompanyClientContact,
    LocationInaddress
  },
  props: {
    existingClient: {
      type: Object,
      default: () => {
        return {};
      }
    },
    project: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      client: {
        name: null,
        email: null,
        telephone_number: null,
        address: null,
        register_code: null,
        tax_code: null,
        is_company: false,
        existing: false
      },
      phoneNumberInput: "",
      contactDetails: false,
      clientContacts: [],
      isCompany: false,
      countryCode: "+372"
    };
  },
  methods: {
    handleClientLocationChange(e) {
      this.client.address = e;
    },
    assignPhoneNumber() {
      this.client.telephone_number = this.fullPhoneNumber;
    },
    saveClientContact(contact) {
      this.clientContacts.push(contact);
      this.$emit("clientContactsUpdated", this.clientContacts);
    },
    removeContact(index) {
      this.clientContacts.splice(index, 1);
      this.$emit("clientContactsUpdated", this.clientContacts);
    }
  },
  validations() {
    let validationObj = {
      client: {
        name: { required },
        email: { email }
      },
      address: { required }
    };

    if (
      this.client.telephone_number !== null &&
      this.client.telephone_number.length > 0
    ) {
      validationObj.telephone_number = { required };
    }

    return validationObj;
  },
  computed: {
    fullPhoneNumber() {
      return this.countryCode + this.phoneNumberInput.replace(" ", "");
    }
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.$v.$touch();
        this.$emit("clientUpdated", this.client);
      }
    },
    contactDetails() {
      this.$emit("editingContact", this.contactDetails);
    }
  }
};
</script>
